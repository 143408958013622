<script setup>
import VaildationInputListVue from '@/components/ui/VaildationInputList.vue'
import { signIn } from '@/api/auth'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { validateLoginPassword, validateLoginId } from '@/util/reg-validators'

import LanguageConverter from '@/util/LanguageConverter'

const router = useRouter()
const store = useStore()

const vaildation = ref(null)

const redirectLinks = [
  { link: '/member/register', name: '회원가입', nameEn: 'Sign up' },
  { link: '/member/findId', name: '아이디찾기', nameEn: 'Find ID' },
  { link: '/member/findPw', name: '비밀번호찾기', nameEn: 'Find Password' }
]

const validationInputs = [
  {
    type: 'text',
    placeholder: LanguageConverter({ en: 'ID', kr: '아이디' }),
    isValidFunc: (el) => validateLoginId(el.value),
    vaildText: '',
    invalidText: '10 자리 내의 영문자, 숫자만 입력가능합니다.'
  },
  {
    type: 'password',
    placeholder: LanguageConverter({ en: 'Password', kr: '비밀번호' }),
    isValidFunc: (el) => validateLoginPassword(el.value),
    vaildText: '',
    invalidText: '영문자, 숫자, (!, @, #, $, %, ^, &, *)만 입력가능합니다.'
  }
]

const formSubmitHandler = async (e) => {
  if (!vaildation.value.vaildate()) return

  const [id, pw] = vaildation.value.submitData
  const result = await signIn(id.value, pw.value)

  // 로그인 실패
  if (result.code === 10000) return alert('잘못된 비밀번호입니다.')
  if (result.code === 10001) return alert('가입되지 않은 아이디입니다.')
  if (!result.token) return alert('unknown Error')

  // 로그인 성공
  localStorage.setItem('token', result.token) // 저장소에 accessToken 저장
  localStorage.setItem('tokenType', result.tokenType) // 저장소에 tokenType 저장

  /**
   * refreshToken을 localStorage에 저장할 경우 보안에 취약점이 발생
   */
  store.dispatch('userStore/SetRefreshToken', result.refreshToken) // store객체에 refreshToken저장

  router.replace('/')
}
</script>

<template>
  <article class="wf__login-form">
    <div class="wf__login-form-wrapper">
      <div class="wf__login-form-title-wrapper">
        <h4 class="wf__login-form-title">
          {{ LanguageConverter({ en: 'Log In', kr: '로그인' }) }}
        </h4>
        <h5 class="wf__login-form-subtitle">
          {{ LanguageConverter({ en: 'Find The Knowledge You Want', kr: '연구실과 기업을 연결하는 공간' }) }}
        </h5>
        <h6 class="wf__login-form-description">
          {{
            LanguageConverter({
              en: 'Welcome to PromisingLabs. Please use the service after logging in.',
              kr: '유망연구실에 오신 것을 환영합니다. 로그인 후 서비스를 사용해주세요.'
            })
          }}
        </h6>
      </div>

      <form class="wf__login-form-body" @submit.prevent="formSubmitHandler">
        <section class="wf__login-form-input-wrapper">
          <VaildationInputListVue ref="vaildation" :items="validationInputs" />
        </section>

        <ul class="wf__login-form-redirects">
          <li v-for="(item, i) in redirectLinks" :key="i">
            <router-link :to="item.link">{{ LanguageConverter({ en: item.nameEn, kr: item.name }) }}</router-link>
          </li>
        </ul>

        <button class="wf__login-form-submit" type="submit">로그인</button>
      </form>
    </div>
  </article>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__login-form {
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: var(--wf-text-16);
}
.wf__login-form-wrapper {
  max-width: 468px;
}
.wf__login-form,
.wf__login-form-title-wrapper,
.wf__login-form-body {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.wf__login-form-title-wrapper {
  gap: 0.5em;
}
.wf__login-form-title {
  font-size: 36px;
  font-weight: bold;
}
.wf__login-form-subtitle {
  font-size: 24px;
  color: var(--wf-secondary-color);
}
.wf__login-form-description {
  font-size: 16px;
  color: var(--wf-gray-color);
}

.wf__login-form-body {
  align-items: center;
  margin-top: var(--wf-text-30);
}
.wf__login-form-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;

  width: 100%;
}

.wf__login-form-redirects {
  transform: translateX(10px);
  display: flex;
  gap: 1em;
}
.wf__login-form-redirects li {
  display: flex;
  align-items: center;
  gap: 1em;
}
.wf__login-form-redirects li:not(:last-child)::after {
  content: '';
  display: block;
  background-color: var(--wf-gray-color);
  width: 2px;
  height: 65%;
}

.wf__login-form-submit {
  outline: none;
  border: none;
  color: #fff;
  background-color: var(--wf-primary-color);
  border-radius: 0.25em;
  letter-spacing: 0.5px;
  font-weight: 400;
  padding: 0.35em 1em;
}
.wf__login-form-submit:hover {
  opacity: 0.8;
}
</style>
